module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Salon Solei","short_name":"Salon Solei","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0400090cae1ebf4c53c153a95ccc4894"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.salonsolei.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceCategory","templateName":"ServiceCategory","recipe":"cutilier","tagName":"ServiceCategories","fishermanParentPageType":"Services","orderPath":"order","groupPath":"schedule.name","groupOrderPath":"schedule.order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_2420/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_1"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"ServiceItem","templateName":"ServiceItem","recipe":"cutilier","tagName":"ServiceItems","fishermanParentPageType":"Services","orderPath":"order","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_2420/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_2"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/gatsby-browser.js'),
      options: {"plugins":[],"queryName":"TeamMember","templateName":"TeamMember","recipe":"cutilier","fishermanParentPageType":"Team","layoutComponent":"/app/workspaces/v_1/website_envs/merchants_2420/src/components/WebsiteLayout.jsx","instanceId":"gatsby-plugin-fisherman-page-creator_3"},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-locations/gatsby-browser.js'),
      options: {"plugins":[],"useStorage":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-animations/gatsby-browser.js'),
      options: {"plugins":[],"enabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5N2BVXP5","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
